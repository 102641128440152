<template>
	<div>
		<b-card title="Coupon List">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
					<router-link
						to="/create-coupon-list"
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
						v-if="checkPermission('add coupon')"
					>
						Generate New Coupon
					</router-link>
				</b-col>
				<b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex">
							<div class="position-relative">
								<input 
									type="search"
									class="form-control"
									v-model="filter.keyword" 
									placeholder="Search: Name Coupon" 
									aria-label="Search"
								>
							</div>
							<!-- <button class="btn btn-outline-primary my-sm-0 mx-sm-2" type="submit">Search</button> -->
							<button 
								:disabled="!filter.keyword" 
								@click="filter.keyword = ''" 
								class="btn btn-outline-secondary ml-sm-2"
							>
								Reset
							</button>
						</div>
					</form>
				</b-col>
			</b-row>
			<b-table 
				striped 
				hover 
				:items="coupons" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>

				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * 10 + index + 1 }}
				</template>

				<template v-slot:cell(is_active)="{ item }">
					<b-badge
						variant="light-success"
						v-if="item.is_active == true"
					>
						True
					</b-badge>
					<b-badge
						variant="light-danger"
						v-else
					>
						False
					</b-badge>
				</template>

				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<router-link
							:to="'/edit-coupon-list/'+ item.uuid"
							v-if="checkPermission('update coupon')"
						>
							<b-badge 
								variant="warning"
								class="badge-glow"
							>
								<feather-icon
									:id="`invoice-row-${item.uuid}-send-icon`"
									icon="EditIcon"
									class="cursor-pointer"
									size="16"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									v-b-tooltip.hover.bottom="'Edit Data'"
								/>
							</b-badge>
						</router-link>
						
						<b-badge 
							variant="danger"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="TrashIcon"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Delete Data'"
								v-if="checkPermission('delete coupon')"
								@click="deleteItem(item.uuid)"
							/>
						</b-badge>
					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	VBTooltip,
	BPagination,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no','code', 'is_active', 'start_from', 'expire_at', 'amount', 'amount_type', 'used', 'actions'],
			coupons: [],
			filter: {
				keyword: ''
			},
		}
	},

	computed: {
		rows() {
			return this.coupons.length
		}
	},

	methods: {
		getData(page) { //page = 1
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/coupons', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.coupons = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
		deleteItem(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Kupon ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/coupons/' + uuid)
					this.getData(this.currentPage)
					successNotification(this, 'Success', 'Coupon list berhasil dihapus!')
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Ada Kesalahan teknis')
				}
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>

<style>
	
</style>
